<template>
  <div>
    <div class="e-breadcrumb">会员分析统计</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">会员充值统计
              </div>
<!--              <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">会员欠款统计-->
<!--              </div>-->
            </div>
          </div>
          <TabRecharge  v-if="tabIndex==0"/>
<!--          <TabArrears  v-if="tabIndex==1"/>-->
          <!--<TabIntegral  v-if="tabIndex==1"/>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import TabArrears from './arrears/entry'
  import TabRecharge from './recharge/entry'

  export default {
    data() {
      return {
        tabIndex:0,
        item: {},
      }
    },
    async created() {
    },
    methods: {
      switchTable(tabIndex) {
        this.tabIndex = tabIndex
      },
      // bbi_package_commission
    },
    components: {
      TabRecharge,
    }
  }

</script>

<style scoped>
  .e-table-wrapper tbody .el-button{
    padding: 3px 0px;
  }
</style>
