<template>
  <div>
    <div class="order-table-wrapper" style="padding-top:0px">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:30px">
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">门店</span>
              <el-select size="small" clearable @change="getCardList()" v-model="departmentCode" placeholder="选择门店">
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span class="v-form-label">日期</span>
              <el-date-picker
                size="small"
                v-model="cxDate"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <span class="v-form-label">卡名称:</span>
              <div class="e-tabs-wrapper" style="margin-left: 8px">
                <div class="e-tabs-item " :class="{'active':tabIndex==index}" v-for="(item,index) in cardList"
                     @click="switchTable(index,item.id)">{{item.cardName}}
                </div>
                <div class="e-tabs-item " :class="{'active':tabIndex==-1}" @click="switchTable(-1)">全部
                </div>
              </div>
              <el-row type="flex" justify="end">
                <el-button size="small" class="e-button" style="margin-left: 18px" type="primary"
                           @click="getMcRecharge()">
                  查询
                </el-button>
              </el-row>
            </div>
          </div>
          <div>

          </div>
          <el-table
            :data="list"
            border
            :header-cell-style="{background:'#fff','font-weight':'bold'}"
            style="width: 100%;" stripe>
            <el-table-column prop="memberName" label="顾客姓名" align="center">
              <template slot-scope="scope">
                <el-button size="small" type="text"
                           @click="toDetailed(scope.row)">
                  {{scope.row.memberName}}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="cardName" label="卡名称" align="center">
            </el-table-column>
            <el-table-column prop="totalAmount" label="充值总金额" align="center">
            </el-table-column>
            <el-table-column prop="consumptionAmount" label="消费总金额" align="center">
            </el-table-column>
            <el-table-column prop="consumptionNum" label="消费次数" align="center">
            </el-table-column>
            <el-table-column prop="itemTotal" label="开卡/续卡" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.orderType==3">续卡</span>
                <span v-if="scope.row.orderType==2">开卡</span>
              </template>
            </el-table-column>
            <el-table-column prop="rechargeAmount" label="金额" align="center">
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :pager-count="7"
              layout="total, prev, pager, next,jumper"
              :total="pagination.count"
              :page-count="pagination.pages"
              :current-page="pagination.pageNumber"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="会员明细" :visible.sync="mdVisible" width="70%">
      <el-row>
        <el-col :span="11">
          <span style="font-weight: bold;font-size: 18px">充值明细</span>
          <div class="dialog-tab">
            <el-table
              :data="czList"
              stripe
              border
              :header-cell-style="{background:'#fff','font-weight':'bold'}"
              style="width: 100%;">
              <el-table-column prop="orderNo" label="单号" align="center">
              </el-table-column>
              <el-table-column prop="cardName" label="卡名称" align="center">
              </el-table-column>
              <el-table-column prop="totalAmount" label="充值金额" align="center">
              </el-table-column>
              <el-table-column prop="createTime" label="充值时间" align="center">
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="czCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="czPagination.count"
                :page-count="czPagination.pages"
                :current-page="czPagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </el-col>
        <el-col :span="11" style="margin-left: 100px">
          <span style="font-weight: bold;font-size: 18px">消费明细</span>
          <div class="dialog-tab">
            <el-table
              :data="xfList"
              border
              :header-cell-style="{background:'#fff','font-weight':'bold'}"
              style="width: 100%;">
              <el-table-column prop="orderNo" label="单号" align="center">
              </el-table-column>
              <el-table-column prop="cardName" label="卡名称" align="center">
              </el-table-column>
              <el-table-column prop="totalAmount" label="消费金额" align="center">
              </el-table-column>
              <el-table-column prop="createTime" label="消费时间" align="center">
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="xfCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="xfPagination.count"
                :page-count="xfPagination.pages"
                :current-page="xfPagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        tabIndex: -1,
        storeList: [],
        cardList: [],
        cardId: '',
        cxDate: [],
        departmentCode: '',

        list: [],
        size: 0,
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },


        mdVisible: false,
        memberId: '',
        xfList: [],
        xfSize: 0,
        xfPagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        czList: [],
        czSize: 0,
        czPagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },
      }
    },
    async created() {
      this.getStoreList()
      this.getCardList()
      this.getMcRecharge()
    },
    methods: {

      toDetailed(item) {
        this.memberId = item.memberId
        this.mdVisible = true
        this.getMrDetailed()
        this.getMcDetailed()
      },

      //充值明细
      czCurrentChange(page) {
        this.getMrDetailed(page)
      },

      async getMrDetailed(page,) {
        let currentPage = page || 1;
        let staDate = '';
        let endDate = '';
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0]
          endDate = this.cxDate[1]
        }
        let data = {
          memberId: this.memberId,
          staDate: staDate,
          endDate: endDate,
          size: this.mdSize == '' ? '10' : this.mdSize,
          currentPage
        }
        let res = await this.$get("/admin/getMrDetailed", data)
        if (res.code == 200) {
          this.czList = res.dataSet.list
          this.czPagination = {
            count: res.dataSet.count,
            pageNumber: res.dataSet.pageNumber,
            pageSize: res.dataSet.pageSize,
            pages: res.dataSet.pages
          }

        }
      },


      //消费明细
      xfCurrentChange(page) {
        this.getMcDetailed(page)
      },
      async getMcDetailed(page,) {
        let currentPage = page || 1;
        let staDate = '';
        let endDate = '';
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0]
          endDate = this.cxDate[1]
        }
        let data = {
          memberId: this.memberId,
          staDate: staDate,
          endDate: endDate,
          size: this.mdSize == '' ? '10' : this.mdSize,
          currentPage
        }
        let res = await this.$get("/admin/getMcDetailed", data)
        if (res.code == 200) {
          this.xfList = res.dataSet.list
          this.xfPagination = {
            count: res.dataSet.count,
            pageNumber: res.dataSet.pageNumber,
            pageSize: res.dataSet.pageSize,
            pages: res.dataSet.pages
          }

        }
      },
      async getCardList() {
        this.list = []
        let data = {
          departmentCode: this.departmentCode
        }
        let res = await this.$get("/admin/getStoreCardList", data)
        if (res.code == 200) {
          this.cardList = res.cardList
        } else {
          this.$message.error(res.message)
        }
      },

      switchTable(index, id) {
        this.cardId = id
        this.tabIndex = index
      },

      async getStoreList() {
        let res = await this.$get("/admin/getStoreList")
        this.storeList = res;
      },

      handleCurrentChange(page) {
        this.getMcRecharge(page)
      },

      async getMcRecharge(page) {
        let currentPage = page || 1;
        let staDate = '';
        let endDate = '';
        if (this.cxDate && this.cxDate.length > 0) {
          staDate = this.cxDate[0]
          endDate = this.cxDate[1]
        }
        let data = {
          staDate: staDate,
          endDate: endDate,
          cardId: this.cardId,
          departmentCode: this.departmentCode,
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getMemberAnalysis", data)
        if (res.code == 200) {
          this.list = res.dataSet.list
          this.pagination = {
            count: res.dataSet.count,
            pageNumber: res.dataSet.pageNumber,
            pageSize: res.dataSet.pageSize,
            pages: res.dataSet.pages
          }
        }
      },
    },
  }

</script>

<style lang="scss" scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }

  .box-card {
    width: 18%;
    margin-right: 20px;
    display: inline-block;
  }

  .box-cards {
    width: 35%;
    margin-right: 20px;
  }

  .text-item {
    width: 100%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }

  }

  .text-items {
    width: 50%;
    display: inline-block;
    line-height: 30px;
    .item-h1 {
      color: #ce5e05;
      font-weight: bold;
    }
    .item-h2 {
      height: 30px;
      font-size: 12px;
    }
    .item-h3 {
      text-align: center;
      font-weight: bold;
      span {
        margin-right: 5px;
      }
    }
    .item-h4 {
      height: 20px;
      font-size: 12px;
    }
  }

  .dialog-tab {
    border: solid #f0f0f0 1px;
    padding: 15px;
    margin-bottom: 30px;
    margin-top: 15px;
  }
</style>
